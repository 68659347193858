'use client';

import { textVariants2024 } from 'prose-ui/components/text.css';

import { useEffect, useState } from 'react';

import { styled } from '@prose-ui/legacy';

const Root = styled.div`
  position: fixed;
  top: 105px;
  right: 0;
  opacity: 0.9;
  background: rgb(255 0 255 / 90%);
  color: white;
  z-index: 99999;

  font-size: 11px;
  line-height: 1.2;
  padding: 0.5em;
`;

type NodeComputedStylingData = {
  fontFamily: string;
  fontWeight: string;
  fontSize: number;
  letterSpacing: number;
  lineHeight: number;
};

// For now we add these variables to window for having a quick and dirty way
// to update those values for the user (e.g. StyleAudit.referenceStyles = {...} in the console)
declare global {
  interface Window {
    StyleAudit?: {
      referenceStyles: Array<[string, NodeComputedStylingData]>;
      options: { withInfoOverlay: boolean };
    };
  }
}

const extractCustomPropName = (str: string) => str.replace(/^var\(/, '').replace(/\)$/, '');
const extractNumberAndUnit = (value: string) => {
  const matches = value.match(/^(-?[0-9]+(\.[0-9]+)?)([a-z]*)?/i)!;

  return {
    numberFromValue: Number(matches[1]),
    unitFromValue: matches[3],
  };
};

const computeFontSizeInPx = (documentComputedStyles: CSSStyleDeclaration, value: string) => {
  const { numberFromValue, unitFromValue } = extractNumberAndUnit(value);
  if (unitFromValue === 'px') {
    return numberFromValue;
  }
  if (unitFromValue === 'rem') {
    const { numberFromValue: rootFontSize /* this is supposedly in px */ } = extractNumberAndUnit(
      documentComputedStyles.getPropertyValue('font-size'),
    );
    return rootFontSize * numberFromValue;
  }
  return numberFromValue;
};

const computeLineHeightInPx = (fontSizeInPx: number, value: string) => {
  const { numberFromValue, unitFromValue } = extractNumberAndUnit(value);
  if (unitFromValue === 'px') {
    return numberFromValue;
  }
  if (unitFromValue === '' || typeof unitFromValue === 'undefined') {
    return numberFromValue * fontSizeInPx;
  }
  return numberFromValue;
};

const computeLetterSpacingInPx = (fontSizeInPx: number, value: string) => {
  const { numberFromValue, unitFromValue } = extractNumberAndUnit(value);
  if (unitFromValue === 'px') {
    return numberFromValue;
  }
  if (unitFromValue === 'em') {
    return numberFromValue * fontSizeInPx;
  }
  return numberFromValue;
};

const normalizeCssValue = (fontSizeInPx: number, property: string, value: string) => {
  if (property === 'fontFamily') {
    return value.split(',')[0]!.replaceAll('"', '');
  }
  if (property === 'fontSize') {
    return Number(fontSizeInPx.toFixed(2));
  }
  if (property === 'lineHeight') {
    return Number(computeLineHeightInPx(fontSizeInPx, value).toFixed(2));
  }
  if (property === 'letterSpacing') {
    return Number(computeLetterSpacingInPx(fontSizeInPx, value).toFixed(2));
  }
  return value;
};

const getCustomPropertyValue = (documentComputedStyles: CSSStyleDeclaration, value: string) => {
  const customPropName = extractCustomPropName(value);
  const customPropValue = documentComputedStyles.getPropertyValue(customPropName);
  return customPropValue;
};

const setupWindowVariables = () => {
  const documentComputedStyles = getComputedStyle(document.documentElement);

  const parsedVariantDefinitions = Object.entries(textVariants2024).reduce<
    Array<[string, NodeComputedStylingData]>
  >((acc, [variantKey, variantStyles]) => {
    const fontSize = computeFontSizeInPx(documentComputedStyles, variantStyles.fontSize);

    return [
      ...acc,
      [
        variantKey,
        Object.entries(variantStyles).reduce<NodeComputedStylingData>(
          (nodeAcc, [property, value]) => {
            const actualValue = value.startsWith('var(')
              ? getCustomPropertyValue(documentComputedStyles, value)
              : value;

            const normalizedValue = normalizeCssValue(fontSize, property, actualValue);

            if (
              property !== 'fontFamily' &&
              property !== 'fontSize' &&
              property !== 'fontWeight' &&
              property !== 'lineHeight' &&
              property !== 'letterSpacing' &&
              property !== 'textTransform'
            ) {
              console.warn('Unexpected property while parsing Text variant definitions:', property);
              return nodeAcc;
            }

            return {
              ...nodeAcc,
              [property]: normalizedValue,
            };
          },
          {} as NodeComputedStylingData,
        ),
      ],
    ];
  }, []);

  window.StyleAudit = {
    options: { withInfoOverlay: true },
    referenceStyles: parsedVariantDefinitions,
  };
};

type NodeData = NodeComputedStylingData & {
  instance: HTMLElement;
  'matchedVariant(s)': string;
};

const matchAgainstReference = (
  referenceStyles: Array<[string, NodeComputedStylingData]>,
  nodeData: NodeComputedStylingData,
) => {
  const minimumMatches = referenceStyles.filter(
    ([_variantKey, variantValues]) =>
      nodeData.fontFamily === variantValues.fontFamily &&
      nodeData.fontSize === variantValues.fontSize &&
      nodeData.fontWeight === variantValues.fontWeight,
  );

  const exactMatch = minimumMatches.find(
    ([_variantKey, variantValues]) =>
      nodeData.lineHeight === variantValues.lineHeight &&
      nodeData.letterSpacing === variantValues.letterSpacing,
  );

  if (exactMatch) return ['exact_match', `🟢 ${exactMatch[0]}`] as const;

  if (minimumMatches.length > 0) {
    return ['inexact_match', `🟡 ${minimumMatches.map((v) => v[0]).join(', ')}`] as const;
  }

  return ['no_match', '❌'] as const;
};

function executeAuditScript() {
  if (!window?.StyleAudit) return;

  const textNodes: NodeData[] = [];

  const elementsWithText = [
    ...document.querySelectorAll(
      ':is(*:not(:has(> *)):not(:empty), :has(> :is(br, span, strong, b, a, sup, button, .__style-audit-info__))):not(.__style-audit-info__)',
    ),
  ].filter(
    (el) =>
      [...el.childNodes].some((chn) => chn.nodeType === 3) &&
      getComputedStyle(el).display !== 'none',
  );

  elementsWithText.forEach((elementNode) => {
    if (!(elementNode instanceof HTMLElement)) {
      console.warn(
        'elementsWithText contains an element which is not expected, please fix the selection/filtering logic: ',
        elementNode,
      );
      return;
    }

    const computedStyle = getComputedStyle(elementNode);

    const nodeStylingData: NodeComputedStylingData = {
      fontFamily: computedStyle.fontFamily.split(',')[0]!.replaceAll('"', ''),
      fontWeight: computedStyle.fontWeight,
      fontSize: Number(computedStyle.fontSize.slice(0, -2)),
      letterSpacing: Number(computedStyle.letterSpacing.slice(0, -2)),
      lineHeight: Number(computedStyle.lineHeight.slice(0, -2)),
    };

    const match = matchAgainstReference(window.StyleAudit!.referenceStyles, nodeStylingData);
    let matchingColor = '255 0 255'; // unexpected color
    switch (match[0]) {
      case 'exact_match':
        matchingColor = '0 255 0';
        break;
      case 'inexact_match':
        matchingColor = '240 170 0';
        break;
      case 'no_match':
        matchingColor = '255 0 0';
        break;
      default:
        break;
    }

    elementNode.style.setProperty(
      'text-shadow',
      `0 0 0.2ch rgba(${matchingColor}/ 100%), 0 0 0.2ch rgba(${matchingColor}/ 100%)`,
    );
    elementNode.style.setProperty('box-shadow', `inset 0 0 0 100vmax rgba(${matchingColor}/ 18%)`);

    /* remove all .__style-audit-info__ elements if they exist */
    [...document.querySelectorAll('.__style-audit-info__')].forEach((el) => el.remove());

    setTimeout(() => {
      if (window.StyleAudit?.options.withInfoOverlay) {
        /* add all .__style-audit-info__ elements */
        const infoOverlayElement = document.createElement('div');
        infoOverlayElement.setAttribute('class', '__style-audit-info__');
        infoOverlayElement.setAttribute(
          'style',
          `padding: 2px; position: absolute; transform: translateY(-33%); white-space: nowrap; color: white; text-shadow: 0 0 3px black; font-size: 9px; font-family: sans-serif; line-height: 1; background: rgb(${matchingColor} / 33%); text-transform: none;`,
        );
        infoOverlayElement.appendChild(document.createTextNode(match[1]));
        elementNode.appendChild(infoOverlayElement);
      }
    }, 150);

    textNodes.push({
      instance: elementNode,
      ...nodeStylingData,
      'matchedVariant(s)': match[1],
    });
  });

  let generatedStyleIndex = 0;
  const uniqueStyleIndexes: Map<string, number> = new Map([]);

  type LoggedTableRow = NodeComputedStylingData & {
    count: number;
    instances: Element[];
  };

  const textNodesOverviewData = textNodes.reduce(
    (acc, nodeData) => {
      const { instance, ...nodeStyleData } = nodeData;
      const strData = JSON.stringify(nodeStyleData);

      let uniqueStyleIndex = uniqueStyleIndexes.get(strData);

      if (!uniqueStyleIndex) {
        generatedStyleIndex += 1;
        uniqueStyleIndex = generatedStyleIndex;
        uniqueStyleIndexes.set(strData, generatedStyleIndex);
      }

      return {
        ...acc,
        [uniqueStyleIndex]: {
          ...nodeStyleData,
          count: (acc[uniqueStyleIndex]?.count || 0) + 1,
          instances: acc[uniqueStyleIndex]?.instances
            ? [...acc[uniqueStyleIndex]!.instances, instance]
            : [instance],
        },
      };
    },
    {} as Record<number, LoggedTableRow>,
  );

  console.log(
    `----------///---------- Style audit on page ${window.location.href} ----------///---------- `,
  );
  console.log('Elements containing text:', textNodes.length);
  console.log('Unique text stylings:', Object.values(textNodesOverviewData).length);
  console.table(textNodesOverviewData);
  console.log(
    '----------------------------------------------------------------------------------------- ',
  );
}

export const StyleAuditTool = () => {
  const [isGUIActive, setIsGUIActive] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setupWindowVariables();
    }

    const callback = (event: KeyboardEvent) => {
      if (/* press option+A or alt+A */ event.code === 'KeyA' && event.altKey) {
        executeAuditScript();
        setIsGUIActive(true);
      }
    };
    document.body.addEventListener('keydown', callback);
    console.log(
      "The style-audit-tool is active. Press 'option+A' (or 'alt+A') while focusing the website to activate it.",
    );

    return () => {
      document.body.removeEventListener('keydown', callback);
    };
  }, []);

  return isGUIActive && <Root>⚙️ YOU ARE DEBUGGING STYLES</Root>;
};
